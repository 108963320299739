import { useEffect } from "react";

function App() {
  useEffect(() => {
    const urlPath = window.location.href.split("open/download/")[1];
    window.open(urlPath);
  }, []);

  return <></>;
}

export default App;
